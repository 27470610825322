<header class="sticky"  [class.fixed]="stick">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid logo" alt="logo" />
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div class="d-lg-none" style="display: flex; align-items: center; padding-right: 10px;" >
              <div class="" style="padding-right: 10px;" *ngIf="isLoggedIn">
                <div class="">
                  <a [routerLink]="['/store/cart']">
                    <i class="ti-shopping-cart"></i>
                  </a>
                </div>
              </div>
              <div class="mobile-account" routerLink="/me/account" style="cursor: pointer;"  *ngIf="isLoggedIn">
                <i class="fa fa-user" aria-hidden="true"></i>My Account
              </div>
            </div>
            
            <div class="app-menu">
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings [isLoggedIn]="isLoggedIn"></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
