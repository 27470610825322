<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Make Payment</h5>
    <button type="button" class="close" (click)="handleModalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="paymentElement?.state != 'ready'">
      <div class="d-flex row justify-content-center">
        <img src="../../../../assets/images/loader.svg" alt="" />
      </div>
    </div>
    <div class="container-fluid p-0">
      <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
        <ngx-stripe-payment [clientSecret]="clientSecret"> </ngx-stripe-payment>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer" *ngIf="paymentElement?.state == 'ready'">
    <button type="button" class="btn btn-primary" (click)="stripeCheckout()">
      Pay
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="handleModalClose()"
    >
      Cancel
    </button>
  </div>
</div>
<!-- <div class="mt-3 p-2">
  <ngx-paypal [config]="payPalConfig"></ngx-paypal>
</div> -->
