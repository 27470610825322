<section class="page-title glass-login">
  <div class="auto-container" [ngClass]="{'text-center' : title == 'auth'}">
    <div class="title-outer">
      
      <h1 class="title" [ngClass]="{'auth-title' : title == 'auth'}">{{breadcrumb}}</h1>
      <ul class="page-breadcrumb" *ngIf="title !== 'auth';">
        <li><a href="">Home</a></li>
        <li><a href="#">Pages</a></li>
        <li>{{breadcrumb}}</li>
      </ul>
      <ul class="page-breadcrumb" *ngIf="title == 'auth'">
        <li><i>Welcome to Luxury Leaf Co., your one-stop destination for all your
          smoke shop needs.</i></li>
       
      </ul>
    </div>
  </div>
</section>