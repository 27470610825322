<div class="icon-nav d-none d-lg-block">
  <ul>
    <li class="mobile-account" routerLink="/me/account" style="cursor: pointer;" *ngIf="isLoggedIn">
      <i class="fa fa-user" aria-hidden="true"></i>My Account 
    </li>

    <li class="onhover-div mobile-cart" *ngIf="isLoggedIn">
      <div>
        <a [routerLink]="['/store/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="" />
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf="!products.length">
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf="products.length">
        <li *ngFor="let product of products">
          <div class="media">
            <a
              [routerLink]="[
                '/store/product/',
                product.title.replace(' ', '-')
              ]"
            >
              <img
                class="mr-3"
                [src]="product?.images[0].src"
                [alt]="product?.images[0].alt"
              />
            </a>
            <div class="media-body">
              <a
                [routerLink]="[
                  '/store/product/',
                  product.title.replace(' ', '-')
                ]"
              >
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x
                  {{
                    product?.price * productService?.Currency.price
                      | discount : product
                      | currency : productService?.Currency.currency : "symbol"
                  }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"
              ><i class="fa fa-times" aria-hidden="true"></i
            ></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>
              subtotal :
              <span>{{
                getTotal
                  | async
                  | currency : productService?.Currency.currency : "symbol"
              }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/store/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/store/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
