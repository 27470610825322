<!--footer section -->
<footer >
  <!-- <div class="light-layout" *ngIf="newsletter">
    <div class="container">
      <section class="small-section border-section border-top-0">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="subscribe">
              <div>
                <h4>HEAR THE INFO FIRST!</h4>
                <p>Never Miss Anything By Signing Up To Our Newsletter.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <form class="form-inline subscribe-form">
              <div class="form-group mx-sm-3">
                <input
                  type="email"
                  #name
                  name="EMAIL"
                  id="mce-EMAIL"
                  class="form-control"
                  placeholder="Enter your email"
                />
              </div>
              <button type="submit" class="btn btn-solid mt-2">subscribe</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div> -->
  <section class="section-b-space " style="max-height: fit-content !important; background-color: none !important;"
  >
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          
          <div class="footer-contant">
            <div class="footer-logo">
              <img [src]="themeLogo" alt="logo" class="logo" />
            </div>
            <p>
              We are dedicated to providing you with a high-quality smoking experience , we offer flower, accessories, and expert advice to our valued customers.
            </p>
            <div class="footer-social">
              <ul>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                </li>
                
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
        <div class="col offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Prefernces</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a routerLink="/store/collections?category=indica">Indica</a></li>
                <li><a routerLink="/store/collections?category=hybrid">Hybrid</a></li>
                <li><a routerLink="/store/collections?category=sativa">Sativa</a></li>
                <li><a routerLink="/store/collections?category=wax">Wax</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Pages</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/me/account">My Account</a></li>
                <li><a routerLink="/me/my-membership">Membership</a></li>
                <li><a routerLink="/l/faq">FAQ</a></li>
                <li><a routerLink="/l/contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Reach Out</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li>
                  <i class="fa fa-map-marker"></i>Binary RD, Silicon Valley.
                </li>
                <li><i class="fa fa-phone"></i>Reach Us: 123-456-789</li>
                <li>
                  <i class="fa fa-envelope-o"></i>Email Us:
                  <a>support@luxuryleafs.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }} luxury leafs LLC
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
